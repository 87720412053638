import { useEffect } from 'react'
import { useSession } from 'next-auth/client'
import useRequest from '@/utils/useRequest'
import { useRecoilState } from 'recoil'
import { tagsState } from '@/store'

export default function useTags() {
  const [session] = useSession()
  const { data: serverTags, mutate } = useRequest(session ? '/api/tags' : null)
  const [tags, setTags] = useRecoilState(tagsState)

  useEffect(() => {
    if (session && serverTags) {
      setTags(serverTags)
    }
  }, [serverTags, setTags, session])

  return [tags, setTags, mutate]
}
