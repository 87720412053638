import { MobileMenu, DesktopMenu, MobileMenuButton } from './Menu'
import Loader from '@/components/Loader'
import { getIsPrivate } from '@/utils/index'
import { useRouter } from 'next/router'
import { MobileAnnotationsButton } from '@/components/AnnotationsView/panel/AnnotationsPanel'
import Logo from 'public/img/logos/logo.svg'
import classNames from 'classnames'
import { NextSeo } from 'next-seo'
import getSeo from '@/utils/seo'

export default function AppSection({
  children,
  title,
  description,
  isLoading = false,
  loadingMessage,
  headerLeft,
  headerRight,
  wrapContent = true,
  canonical,
}) {
  const router = useRouter()
  const pathname = router.pathname
  const showAppMenu = getIsPrivate(pathname)
  const isDemo = pathname === '/demo'
  const showAnnotationsMenu =
    pathname === '/videos/[id]' || pathname === '/watch/[id]' || isDemo
  const annotationsMenuClassName = classNames('flex lg:hidden absolute z-50', {
    'p-1 pl-3': isDemo,
    'p-3': !isDemo,
  })

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* For /video/:id and /watch/:id we are setting the SEO in the route */}
      {title && <NextSeo {...getSeo({ title, description, canonical })} />}

      {showAppMenu && (
        <>
          <MobileMenu />
          <DesktopMenu />
        </>
      )}

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex="0"
        >
          {showAnnotationsMenu ? (
            <div className={annotationsMenuClassName}>
              {showAppMenu && <MobileMenuButton isBlue />}
              {showAnnotationsMenu && <MobileAnnotationsButton />}
            </div>
          ) : (
            showAppMenu && (
              <div className="flex px-8 py-3 lg:hidden z-50 items-center">
                <MobileMenuButton />
                <div className="flex center w-full -ml-9">
                  <Logo className="h-8 w-8" />
                </div>
              </div>
            )
          )}

          <Content
            {...{
              title,
              children,
              isLoading,
              loadingMessage,
              headerLeft,
              headerRight,
              wrapContent,
            }}
          />
        </main>
      </div>
    </div>
  )
}

function Content({
  title,
  children,
  isLoading,
  loadingMessage,
  headerLeft,
  headerRight,
  wrapContent,
}) {
  if (wrapContent) {
    return (
      <div className="h-full px-8 py-2 lg:py-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row">
          {headerLeft ? (
            <div className="flex">
              <h1 className="text-3xl font-semibold text-gray-900">{title}</h1>
              <div>{headerLeft}</div>
            </div>
          ) : (
            <h1 className="text-3xl font-semibold text-gray-900">{title}</h1>
          )}
          {headerRight && (
            <div className="mt-5 md:mt-0 md:ml-auto flex">{headerRight}</div>
          )}
        </div>

        {isLoading ? (
          <div className="max-w-7xl mx-auto h-full">
            <Loader loadingMessage={loadingMessage} />
          </div>
        ) : (
          <div className="max-w-7xl mx-auto">
            <div className="py-5">{children}</div>
          </div>
        )}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="h-full p-8">
        <div className="max-w-7xl mx-auto h-full">
          <Loader loadingMessage={loadingMessage} />
        </div>
      </div>
    )
  }

  return children
}
