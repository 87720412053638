export default {
  MONTHLY: {
    name: 'MONTHLY',
    id: 637353,
    price: 5,
  },
  YEARLY: {
    name: 'YEARLY',
    id: 636821,
    discount: 0.2,
  },
  LIFETIME: {
    name: 'LIFETIME',
    id: 644668,
    price: 120,
  },
}
