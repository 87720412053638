export default function Spinner({
  className = '',
  size = 10,
  color = 'rgb(75, 85, 99)',
}) {
  const ringStyle = {
    border: `2px solid ${color}`,
    borderColor: `${color} transparent transparent transparent`,
  }

  return (
    <div className={`lds-ring w-${size} h-${size} ${className}`}>
      <div style={ringStyle} />
      <div style={ringStyle} />
      <div style={ringStyle} />
      <div style={ringStyle} />
    </div>
  )
}
