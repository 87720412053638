import { getTimeFromSeconds } from '@/utils/index'

export default function getVideoMd({ video, annotations }) {
  const videoTitleMd = `# ${video.title} (${getTimeFromSeconds(
    video.duration
  )})`

  const videoLinks = `
[Annotate.tv Editor](https://annotate.tv/videos/${video._id})
[Annotate.tv Video](https://annotate.tv/watch/${video._id})
`

  const annotationsMd = annotations.reduce((acc, annotation) => {
    const title = annotation.title
      ? `${getTimeFromSeconds(annotation.start)}: ${annotation.title}`
      : `${getTimeFromSeconds(annotation.start)}: Without title`
    const content = annotation.content || ''
    const annotationMd = `## [${title}](https://annotate.tv/watch/${video._id}?annotationId=${annotation._id})

${content}`
    return `${acc}

${annotationMd}
`
  }, '')

  const md = `${videoTitleMd}
${videoLinks}
${annotationsMd}
`

  return md
}
