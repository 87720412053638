import React from 'react'
import { useEffect } from 'react'
import Script from 'next/script'
import { useSession } from 'next-auth/client'
import analytics from '@/utils/analytics'

export default function Splitbee() {
  const [session] = useSession()

  useEffect(() => {
    if (session && session.user) {
      const user = session.user
      analytics.identify({
        USER_ID: user._id,
        $name: user.name,
        $email: user.email,
      })
    }
  }, [session])

  return <Script data-api="/_hive" src="/bee.js" strategy="afterInteractive" />
}
