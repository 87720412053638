import { Transition } from '@headlessui/react'
import { X } from 'react-feather'

export default function Panel({ isOpen, setIsOpen, children }) {
  const style = isOpen ? {} : { display: 'none' }

  return (
    <div className="lg:hidden" style={style}>
      <div className="fixed inset-0 flex z-50">
        <Transition
          show={isOpen}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" onClick={() => setIsOpen(false)}>
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
        </Transition>

        <Transition
          show={isOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="relative flex-1 flex flex-col max-w-xs w-full bg-transparent"
        >
          <>
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                onClick={() => setIsOpen(false)}
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Close sidebar</span>
                <X className="h-6 w-6 text-white" />
              </button>
            </div>
            <div className="flex-1 h-0 overflow-y-auto">{children}</div>
          </>
        </Transition>
      </div>
    </div>
  )
}
