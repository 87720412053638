import { useRecoilState } from 'recoil'
import { isPremiumState } from '@/store'
import { useState } from 'react'
import { useSession } from 'next-auth/client'
import { useRouter } from 'next/router'
import analytics from '@/utils/analytics'

export default function Premium({
  children,
  addOpacity = false,
  feature,
  isEnabled = false,
  premiumStyle = {},
}) {
  const [session, loading] = useSession()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isPremium] = useRecoilState(isPremiumState)
  const router = useRouter()

  const childrenStyle = { opacity: addOpacity ? '.5' : '1' }

  function onClick() {
    analytics.track('Premium feature clicked', {
      feature,
    })

    if (!loading && session) {
      router.push({
        pathname: '/upgrade',
      })
    } else {
      localStorage.setItem('savePremium', true)
      router.push({
        pathname: '/login',
        query: { callbackUrl: `${document.location.origin}/settings` },
      })
    }
  }

  if (isEnabled || isPremium) {
    return children
  } else {
    return (
      <div
        className="is-premium-wrapper"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onClick={onClick}
      >
        {isMouseOver && (
          <span className="is-premium" style={premiumStyle}>
            🔥 Go Premium
          </span>
        )}
        <div
          style={childrenStyle}
          className={`is-premium-children ${isMouseOver ? 'blurred' : ''}`}
        >
          {children}
        </div>
      </div>
    )
  }
}
