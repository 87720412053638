import { useRecoilState } from 'recoil'
import {
  isAppMobileMenuOpenState,
  ocrState,
  isHiddenPanelState,
  useRecoilStateWithLocalStorage,
} from '@/store'
import Logo from 'public/img/logos/logo.svg'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  LogOut,
  Film,
  PlusCircle,
  Settings,
  HelpCircle,
  Radio,
  Menu,
} from 'react-feather'
import { signOut } from 'next-auth/client'
import analytics from '@/utils/analytics'
import Panel from '@/components/Panel'

const iconStyle = { width: '30px', height: '30px' }

const topItems = [
  {
    pathname: '/videos',
    tooltip: 'Videos',
    icon: <Film style={iconStyle} />,
    name: 'Videos',
  },
  {
    pathname: '/new',
    tooltip: 'New',
    icon: <PlusCircle style={iconStyle} />,
    name: 'New',
  },
  {
    pathname: '/settings',
    tooltip: 'Settings',
    icon: <Settings style={iconStyle} />,
    name: 'Settings',
  },
]

function logOutAndRedirect() {
  signOut({
    callbackUrl: `${document.location.origin}`,
  })
}

export function DesktopMenu({}) {
  const [isHiddenPanel] = useRecoilStateWithLocalStorage({
    state: isHiddenPanelState,
    defaultValue: false,
  })
  const [ocr] = useRecoilState(ocrState)
  const router = useRouter()
  const activePathname = router.pathname
  const sectionsWhereShouldHideMenu = ['/videos/[id]', '/watch/[id]']
  const shouldHideMenu =
    isHiddenPanel && sectionsWhereShouldHideMenu.includes(activePathname)

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div
        style={{
          transition:
            'width 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
        className={`flex flex-col ${
          shouldHideMenu ? 'w-0 pointer-events-none opacity-0' : 'w-16'
        }`}
      >
        <div
          className={`flex flex-col h-0 flex-1 bg-white ${
            ocr ? 'lg:hidden' : ''
          }`}
        >
          <div className="flex-1 flex flex-col pt-9 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 mb-7">
              <Logo className="h-8 w-auto" />
            </div>
            <nav className="mt-5 flex-1 px-3 bg-white space-y-5">
              {topItems.map(({ pathname, icon, tooltip }) => {
                const isActive = activePathname === pathname
                const className = isActive
                  ? 'center bg-primary text-white rounded-md'
                  : 'center bg-lighterblue text-primary rounded-md hover:bg-primary hover:text-white'

                return (
                  <Link href={pathname} key={pathname}>
                    <a className={className}>
                      <div className="px-1 py-1" data-tip data-for={pathname}>
                        {icon}
                      </div>
                      <ReactTooltip
                        arrowColor="transparent"
                        className="tooltip-custom"
                        uuid={pathname}
                        id={pathname}
                        place="right"
                        effect="solid"
                      >
                        {tooltip}
                      </ReactTooltip>
                    </a>
                  </Link>
                )
              })}
            </nav>
            <nav className="mt-auto flex flex-col justify-end flex-1 px-3 bg-white space-y-5">
              <Changelog />
              <a
                href="https://annotate.tv/watch/5f544d3b428fa400074512cd"
                target="_blank"
                rel="noopener noreferrer"
                className="center bg-lighterblue text-primary rounded-md hover:bg-primary hover:text-white"
              >
                <div className="px-1 py-1" data-tip data-for="/help">
                  <HelpCircle style={{ width: '30px', height: '30px' }} />
                </div>
                <ReactTooltip
                  arrowColor="transparent"
                  className="tooltip-custom"
                  style={{ marginLeft: '10px' }}
                  uuid="/help"
                  id="/help"
                  place="right"
                  effect="solid"
                >
                  Help
                </ReactTooltip>
              </a>
              <a
                onClick={logOutAndRedirect}
                className="center bg-lighterblue text-primary rounded-md hover:bg-primary hover:text-white cursor-pointer"
              >
                <div className="px-1 py-1" data-tip data-for="/logout">
                  <LogOut style={{ width: '30px', height: '30px' }} />
                </div>
                <ReactTooltip
                  arrowColor="transparent"
                  className="tooltip-custom"
                  style={{ marginLeft: '10px' }}
                  uuid="/logout"
                  id="/logout"
                  place="right"
                  effect="solid"
                >
                  Log out
                </ReactTooltip>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export function MobileMenu() {
  const [isAppMobileMenuOpen, setIsAppMobileMenuOpen] = useRecoilState(
    isAppMobileMenuOpenState
  )

  const router = useRouter()
  const activePathname = router.pathname

  return (
    <Panel
      {...{ isOpen: isAppMobileMenuOpen, setIsOpen: setIsAppMobileMenuOpen }}
    >
      <div className="bg-gray-800 h-screen px-3 py-5">
        <div className="flex-shrink-0 flex items-center px-4">
          <Logo className="h-8 w-auto" />
          <span className="text-white ml-3 text-xl font-bold">Annotate.tv</span>
        </div>
        <nav className="mt-5 px-2 space-y-1">
          {topItems.map(({ name, icon, pathname }) => {
            const isActive = activePathname === pathname
            const className = isActive
              ? 'bg-gray-900 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md'

            return (
              <Link href={pathname} key={pathname}>
                <a
                  className={className}
                  onClick={() => setIsAppMobileMenuOpen(false)}
                >
                  {icon}
                  <span className="ml-4">{name}</span>
                </a>
              </Link>
            )
          })}

          <a
            className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
            onClick={logOutAndRedirect}
          >
            <LogOut style={{ width: '30px', height: '30px' }} />
            <span className="ml-4">Logout</span>
          </a>
        </nav>
      </div>
    </Panel>
  )
}

export function MobileMenuButton({ isBlue = false }) {
  const [isAppMobileMenuOpen, setIsAppMobileMenuOpen] = useRecoilState(
    isAppMobileMenuOpenState
  )

  const className = isBlue ? 'bg-primary rounded-full mr-2' : ''
  const iconClassName = isBlue ? 'text-white' : 'text-gray-500 -ml-3'

  return (
    <div className={`${className} z-10`}>
      <button
        onClick={() => setIsAppMobileMenuOpen(!isAppMobileMenuOpen)}
        className={`h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary ${iconClassName}`}
      >
        <span className="sr-only">Open sidebar</span>
        <Menu className="h-6 w-6" />
      </button>
    </div>
  )
}

function Changelog() {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://cdn.headwayapp.co/widget.js'
    document.head.appendChild(script)
    const config = {
      selector: '.headway-badge',
      account: 'x8E2E7',
      callbacks: {
        onShowWidget: function () {
          analytics.track('Changelog Opened')
        },
      },
    }
    script.onload = function () {
      window.Headway.init(config)
    }
  }, [])

  function onPointerEnter() {
    document.getElementById('/changelog').style.visibility = 'visible'
    // document.querySelector('.changelog-menu').style.background = '#6381f3'
    // document.querySelector('.changelog-menu svg').style.color = 'white'
  }

  function onPointerLeave() {
    document.getElementById('/changelog').style.visibility = 'hidden'
    // document.querySelector('.changelog-menu').style.background = '#f0effc'
    // document.querySelector('.changelog-menu svg').style.color = '#6381f3'
  }

  return (
    <span
      // className="headway-badge"
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <a className=" headway-badge center bg-lighterblue text-primary rounded-md hover:bg-primary hover:text-white">
        <div
          className="px-1 py-1 changelog-menu"
          data-tip
          data-for="/changelog"
        >
          <Radio style={{ width: '30px', height: '30px' }} />
        </div>
        <ReactTooltip
          arrowColor="transparent"
          className="tooltip-custom"
          style={{ marginLeft: '10px' }}
          uuid="/changelog"
          id="/changelog"
          place="right"
          effect="solid"
        >
          Changelog
        </ReactTooltip>
      </a>
    </span>
  )
}
