import { getTimeFromSeconds } from '@/utils/index'

export default function getYouTubeTimestamps({ annotations }) {
  const md = annotations.reduce((acc, annotation) => {
    const title = annotation.title
      ? `${getTimeFromSeconds(annotation.start)} ${annotation.title}`
      : `${getTimeFromSeconds(annotation.start)} Without title`

    return `${acc}
- ${title}`
  }, '')

  return md
}
