import Spinner from '@/components/Spinner'

export default function Loader({ className = '', loadingMessage }) {
  return (
    <div className="flex flex-col h-full center p-10">
      <Spinner className={className} />
      {loadingMessage && <h2 className="mt-5">{loadingMessage}</h2>}
    </div>
  )
}
