export default function getSeo(seo) {
  const title = `${seo.title} - Annotate.tv`
  const description =
    seo.description ||
    'Optimize your learning on YouTube. Take notes on videos and capture the key takeaways. Export them to PDF and markdown.'
  const canonical = seo.canonical
    ? `https://annotate.tv${seo.canonical}`
    : 'https://annotate.tv/'

  const defaultSeo = {
    description,
    openGraph: {
      url: 'https://annotate.tv',
      title,
      description,
      type: 'website',
      images: [
        {
          url: 'https://annotate.tv/img/og-image.png',
          width: 1200,
          height: 627,
          alt: 'Annotate.tv',
        },
      ],
      site_name: 'Annotate.tv',
    },
    twitter: {
      handle: '@TucciMatias',
      site: '@Annotate_TV',
      cardType: 'summary_large_image',
    },
  }

  const actualSeo = {
    ...defaultSeo,
    ...seo,
    title,
    description,
    canonical,
  }

  return actualSeo
}

function getSeoDescription(description) {
  const maxSeoDescription = 160
  const ellipsis = '...'

  if (description.length > maxSeoDescription) {
    const shorter = description.slice(0, maxSeoDescription - ellipsis.length)
    return `${shorter}${ellipsis}`
  } else {
    return description
  }
}

export function getVideoSeo({ video, isReadOnly }) {
  const url = `/${isReadOnly ? 'watch' : 'video'}/${video._id}`
  const title = video.title
  const description = getSeoDescription(video.description)
  const ogImage = video.thumbnails.maxres
    ? video.thumbnails.maxres.url
    : video.thumbnails.default.url

  const videoSeo = {
    title,
    description: description,
    canonical: url,
    openGraph: {
      url,
      title: `${title} - Annotate.tv`,
      description: description,
      type: 'website',
      images: [
        {
          url: ogImage,
          alt: title,
          width: 1280,
          height: 720,
        },
      ],
      site_name: 'Annotate.tv',
    },
  }

  return getSeo(videoSeo)
}
