import Dropdown from '@/components/Dropdown'
import {
  MoreHorizontal,
  FileText,
  BookOpen,
  Book,
  Clipboard,
  Youtube,
} from 'react-feather'
import { copyValueToClipBoard } from '@/utils/index'
import analytics from '@/utils/analytics'
import toast from 'react-hot-toast'
import getVideoMd from '@/utils/getVideoMd'
import getYouTubeTimestamps from '@/utils/getYouTubeTimestamps'

export default function VideoMoreOptions({
  className = '',
  annotations,
  video,
}) {
  function handleMenuClick(key) {
    switch (key) {
      case 'copy-md':
        const md = getVideoMd({ video, annotations })

        copyValueToClipBoard(md)

        analytics.track('Markdown copied')
        toast.success('Markdown copied')
        break

      case 'copy-yt':
        const timestamps = getYouTubeTimestamps({ annotations })

        copyValueToClipBoard(timestamps)

        analytics.track('Markdown copied')
        toast.success('Markdown copied')
        break

      case 'md-preview':
        analytics.track('MD previewed')
        window.open(
          `${window.document.location.origin}/api/videos/${video._id}/md`
        )
        break

      case 'pdf-download':
        analytics.track('PDF downloaded')
        window.open(
          `${window.document.location.origin}/api/videos/${video._id}/pdf?download=true`
        )
        break

      case 'csv': {
        // https://readwise.io/import_bulk
        analytics.track('Exported to CSV')

        const headers = [
          'Highlight',
          'Title',
          'Author',
          'URL',
          'Note',
          'Location',
        ]

        const csv = annotations.reduce((acc, annotation) => {
          const highlight = escapeDoubleQuotes(
            annotation.title || annotation.content
          )
          const title = escapeDoubleQuotes(video.title)
          const author = escapeDoubleQuotes(video.channelTitle)
          const url = video.url
          const note = escapeDoubleQuotes(annotation.content)

          return `${acc}\n"${highlight}","${title}","${author}",${url},"${note}",""`
        }, headers.join(','))

        const hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = `${video.title}.csv`
        hiddenElement.click()
        break
      }
      case 'pdf-preview':
        analytics.track('PDF previewed')
        window.open(
          `${window.document.location.origin}/api/videos/${video._id}/pdf`
        )
        break

      default:
        break
    }
  }

  const mainOptions = [
    {
      name: 'Copy Markdown',
      value: 'copy-md',
      isPremium: true,
      icon: (
        <Clipboard className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export Markdown',
      value: 'md-preview',
      isPremium: true,
      icon: (
        <FileText className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export PDF',
      value: 'pdf-preview',
      isPremium: true,
      icon: (
        <BookOpen className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export CSV',
      value: 'csv',
      isPremium: true,
      icon: (
        <Book className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Copy YouTube times',
      value: 'copy-yt',
      isPremium: true,
      icon: (
        <Youtube className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
  ]

  return (
    <Dropdown
      options={mainOptions}
      onChange={handleMenuClick}
      className={className}
      position="left"
    >
      <div className="flex center px-1 py-1 bg-lighterblue text-primary rounded-md clickable-opacity">
        <MoreHorizontal className="text-primary" />
      </div>
    </Dropdown>
  )
}

function escapeDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, "'")
}
