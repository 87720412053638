import { useRecoilState } from 'recoil'
import { modalState, isPremiumState } from '@/store'
import { X, AlertTriangle, Info } from 'react-feather'
import analytics from '@/utils/analytics'
import { useRouter } from 'next/router'
import useOnEsc from '@/utils/useOnEsc'
import Input from '@/components/Input'
import Button from '@/components/Button'
import { useState } from 'react'
import toast from 'react-hot-toast'
import Image from 'next/image'
import Select from 'react-select/creatable'
import useTags from '@/utils/useTags'

/* eslint-disable @next/next/no-img-element */

// TODO: add transitions
export default function ModalComponent() {
  const [modal, setModal] = useRecoilState(modalState)
  useOnEsc({ show: modal, setShow: setModal })

  if (!modal) {
    return null
  }

  if (modal.isConfirm) {
    return <ConfirmModal {...{ modal, setModal }} />
  }

  if (modal.isConfirmSuccess) {
    return <ConfirmModalSuccess {...{ modal, setModal }} />
  }

  if (modal.isEdit) {
    return <EditModal {...{ modal, setModal }} />
  }

  if (modal.isTags) {
    return <TagsModal {...{ modal, setModal }} />
  }

  if (modal.isPlaylistConfirm) {
    return <PlaylistConfirmModal {...{ modal, setModal }} />
  }

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={() => setModal(null)}
              className="bg-white clickable rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <span className="sr-only">Close</span>
              <X className="h-8 w-8" />
            </button>
          </div>
          {modal.showAsFullBody ? (
            modal.body
          ) : (
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center center flex-col w-full sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-2xl leading-6 font-medium text-gray-900 mb-5"
                  id="modal-headline"
                >
                  {modal.title}
                </h3>
                <div className="mt-2 w-full">{modal.body}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function PlaylistConfirmModal({ modal, setModal }) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <Info className="h-6 w-6 text-primary" />
            </div>
            <div className="mt-3 md:-ml-6 text-center center flex-col w-full sm:mt-0">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Playlist Detected
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  You are adding a video that belongs to a playlist.
                  <br /> Do you want to add the entire playlist or just the
                  video?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="clickable-opacity w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={(e) => {
                modal.onAddPlaylistClick(e)
                setModal(null)
              }}
            >
              Add entire playlist
            </button>
            <button
              type="button"
              className="clickable-opacity mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
              onClick={(e) => {
                modal.onAddVideoClick(e)
                setModal(null)
              }}
            >
              Add single video
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function ConfirmModalSuccess({ modal, setModal }) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <Info className="h-6 w-6 text-blue-600" />
            </div>
            <div className="mt-3 md:-ml-6 text-center center flex-col w-full sm:mt-0">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {modal.title}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{modal.body}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="clickable-opacity w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={(e) => {
                modal.onClick(e)
                setModal(null)
              }}
            >
              {modal?.confirmLabel ?? 'Accept'}
            </button>
            <button
              type="button"
              className="clickable mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setModal(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function ConfirmModal({ modal, setModal }) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <AlertTriangle className="h-6 w-6 text-red-600" />
            </div>
            <div className="mt-3 md:-ml-6 text-center center flex-col w-full sm:mt-0">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {modal.title}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{modal.body}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="clickable w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={(e) => {
                modal.onClick(e)
                setModal(null)
              }}
            >
              {modal?.confirmLabel ?? 'Delete'}
            </button>
            <button
              type="button"
              className="clickable mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setModal(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function TagsModal({ modal, setModal }) {
  const [tags, setTags] = useState(modal.tags)
  const [options] = useTags()

  function onConfirm(e) {
    modal.onClick(e, tags)
    setModal(null)
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form className="space-y-6" onSubmit={onConfirm}>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 flex-col w-full md:mt-0">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {modal.title}
                </h3>
                <div className="mt-2 w-full">
                  <Select
                    className="react-select w-full"
                    defaultValue={tags}
                    options={options}
                    tabIndex="-1"
                    noOptionsMessage={() => <span>No Tags</span>}
                    isMulti
                    isSearchable
                    onChange={(updatedTags) => setTags(updatedTags)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="clickable w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white clickable-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onConfirm}
              >
                Accept
              </button>
              <button
                type="button"
                className="clickable mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setModal(null)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function EditModal({ modal, setModal }) {
  const [value, setValue] = useState(modal.value || '')

  function onConfirm(e) {
    if (!value) {
      toast.error('Please, enter something')
      return
    }

    modal.onClick(e, value)
    setModal(null)
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setModal(null)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form className="space-y-6" onSubmit={onConfirm}>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 flex-col w-full md:mt-0">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {modal.title}
                </h3>
                <div className="mt-2 w-full">
                  <Input
                    className="w-full"
                    type="text"
                    value={value}
                    tabIndex="-1"
                    autoFocus
                    onChange={({ target }) => setValue(target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="clickable w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white clickable-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onConfirm}
              >
                Accept
              </button>
              <button
                type="button"
                className="clickable mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setModal(null)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function PremiumText({ feature }) {
  const [, setModal] = useRecoilState(modalState)
  const [isPremium] = useRecoilState(isPremiumState)
  const router = useRouter()
  const isPricingView = router.pathname === '/pricing'
  const isUpgradeView = router.pathname === '/upgrade'
  const showPremiumText = !isPremium && !isPricingView && !isUpgradeView

  if (!showPremiumText) {
    return null
  }

  return (
    <div className="center flex-col mb-5">
      <h1 className="text-lg mb-5">
        This feature is available in the{' '}
        <span className="font-bold">premium</span> plan
      </h1>
      <button
        type="submit"
        onClick={() => {
          setModal(null)
          analytics.track('Open Checkout', {
            feature,
          })
          router.push({
            pathname: '/upgrade',
          })
        }}
        className="group relative flex justify-center py-2 px-4 pl-10 border border-transparent text-sm font-medium rounded-md text-white bg-primary clickable-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:pointer-events-none"
      >
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          🔥
        </span>
        Upgrade to premium
      </button>
    </div>
  )
}

export function useShowTranscriptModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Transcript',
      body: (
        <div>
          <PremiumText feature="Transcript button" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full"
          >
            <source
              type="video/mp4"
              src="/videos/features/transcript-demo.mp4"
            />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowAutoTranscriptModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Autotranscript',
      body: (
        <div>
          <PremiumText feature="Autotranscript button" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full"
          >
            <source
              type="video/mp4"
              src="/videos/features/transcript-autogenerated-demo.mp4"
            />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowOcrModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Text from video',
      body: (
        <div>
          <PremiumText feature="OCR button" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full"
          >
            <source type="video/mp4" src="/videos/features/ocr-demo.mp4" />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowAutotranscriptModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Autotranscript',
      body: (
        <div>
          <PremiumText feature="Autotranscript button" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full"
          >
            <source
              type="video/mp4"
              src="/videos/features/transcript-autogenerated-demo.mp4"
            />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowTranscriptButtonModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Transcript',
      body: (
        <div>
          <PremiumText feature="Transcript button" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full"
          >
            <source
              type="video/mp4"
              src="/videos/features/transcript-button-demo.mp4"
            />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowInstallExtensionModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Text from video',
      body: (
        <div className="center flex-col">
          <h1 className="mb-5">
            To use this feature you need to install the Chrome extension
          </h1>

          <a
            target="_blank"
            href="https://chrome.google.com/webstore/detail/annotatetv/mepbiloikmgpehamobkanmcllidclden?hl=en"
            rel="noopener noreferrer"
          >
            <Button
              className="px-1"
              onClick={() => analytics.track('Chrome extension link clicked')}
            >
              <img
                src="/img/logos/chrome-logo.svg"
                alt="chrome-logo"
                width={30}
                height={30}
              />
              <span className="text-md ml-3">Install extension</span>
            </Button>
          </a>

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full mt-5"
          >
            <source type="video/mp4" src="/videos/features/ocr-demo.mp4" />
          </video>
        </div>
      ),
    })
  }

  return showModal
}

export function useShowLandingDemoModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: '',
      showAsFullBody: true,
      body: (
        <div
          className="relative block h-0 p-0 overflow-hidden mt-10"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 left-0 bottom-0 w-full h-full"
            src="https://www.youtube.com/embed/e0C3RiOvjNI"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ),
    })
  }

  return showModal
}

export function useShowTxtModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'TXT demonstration',
      showAsFullBody: true,
      body: (
        <div
          className="relative block h-0 p-0 overflow-hidden mt-10"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 left-0 bottom-0 w-full h-full"
            src="https://www.youtube.com/embed/HLVxdw5qUwU"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ),
    })
  }

  return showModal
}

export function useShowExtensionModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Chrome extension demonstration',
      showAsFullBody: true,
      body: (
        <div
          className="relative block h-0 p-0 overflow-hidden mt-10"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 left-0 bottom-0 w-full h-full"
            src="https://www.youtube.com/embed/MiiKrvxSNbo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ),
    })
  }

  return showModal
}

export function useShowShortcutModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'iOS shortcut demonstration',
      showAsFullBody: true,
      body: (
        <div
          className="relative block h-0 p-0 overflow-hidden mt-10"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 left-0 bottom-0 w-full h-full"
            src="https://www.youtube.com/embed/4gEpsP328Po"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ),
    })
  }

  return showModal
}

export function useShowBookmarkModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Bookmark button',
      showAsFullBody: true,
      body: (
        <div
          className="relative block h-0 p-0 overflow-hidden mt-10"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 left-0 bottom-0 w-full h-full"
            src="https://www.youtube.com/embed/JnBVLwsYgas"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ),
    })
  }

  return showModal
}

export function useShowTranscriptTabModal() {
  const [, setModal] = useRecoilState(modalState)

  function showModal() {
    setModal({
      title: 'Transcript',
      body: (
        <div className="w-full h-full">
          <PremiumText feature="Transcript view" />

          <video
            loop
            muted
            autoPlay
            playsInline
            preload="1"
            controls
            className="w-full h-full min-w-full min-h-full"
          >
            <source
              type="video/mp4"
              src="/videos/features/transcript-demo.mp4"
            />
          </video>
        </div>
      ),
    })
  }

  return showModal
}
