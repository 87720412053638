export default function Input({
  onChange,
  type,
  name,
  className = '',
  ...rest
}) {
  return (
    <input
      type={type}
      name={name}
      id={name}
      onChange={onChange}
      className={`shadow-sm focus:ring-primary focus:border-primary w-full border-gray-300 rounded-md ${className}`}
      {...rest}
    />
  )
}
