import Dropdown from '@/components/Dropdown'
import { MoreHorizontal, Trash, Link, Twitter } from 'react-feather'
import { copyValueToClipBoard } from '@/utils/index'
import analytics from '@/utils/analytics'
import toast from 'react-hot-toast'

export default function VideoMoreOptions({
  className = '',
  videoId,
  isReadOnly,
  annotation,
  onDelete,
}) {
  function handleMenuClick(key) {
    switch (key) {
      case 'delete':
        onDelete(null, annotation._id)
        break

      case 'copy-url':
        const annotationUrl = getAnnotationUrl({ videoId, annotation })
        copyValueToClipBoard(annotationUrl)
        analytics.track('Annotation URL copied')
        toast.success('Annotation URL copied')
        break

      case 'twitter':
        const baseUrl = `https://twitter.com/intent/tweet?text=`
        const highlight = getTwitterHighlight({ videoId, annotation })
        const url = `${baseUrl}${highlight}`
        window.open(url, '_blank')
        analytics.track('Annotation shared on Twitter')
        break

      default:
        break
    }
  }

  const userOptions = [
    {
      name: 'Delete',
      value: 'delete',
      icon: (
        <Trash className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
  ]

  const mainOptions = [
    {
      name: 'Copy annotation URL',
      value: 'copy-url',
      icon: (
        <Link className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Share on Twitter',
      value: 'twitter',
      icon: (
        <Twitter className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
  ]

  const options = isReadOnly ? mainOptions : userOptions.concat(mainOptions)

  return (
    <Dropdown
      options={options}
      onChange={handleMenuClick}
      className={className}
    >
      <MoreHorizontal className="ml-auto lg:opacity-0 cursor-pointer center rounded-full p-1 group-hover:opacity-100 hover:bg-white hover:text-primary" />
    </Dropdown>
  )
}

function getAnnotationUrl({ videoId, annotation }) {
  return `${window.document.location.origin}/watch/${videoId}?annotationId=${annotation._id}`
}

function getTwitterHighlight({ videoId, annotation }) {
  const annotationUrl = getAnnotationUrl({ videoId, annotation })

  let highlight = ''

  if (annotation.title && annotation.content) {
    highlight = `${annotation.title}: ${annotation.content}`
  } else {
    highlight = annotation.title || annotation.content
  }

  return `${escapeDoubleQuotes(highlight)} ${annotationUrl}`
}

function escapeDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, "'")
}
