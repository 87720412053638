import { useEffect } from 'react'

export default function useOnEsc({ show, setShow }) {
  useEffect(() => {
    function handleEscape(event) {
      if (!show) return

      if (event.key === 'Escape') {
        setShow(false)
      }
    }

    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [show])
}
