import { useEffect } from 'react'

export default function useOnClickOutside({ container, show, setShow }) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (container.current) {
        if (!container.current.contains(event.target)) {
          if (!show) return
          setShow(false)
        }
      }
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [show, container])
}
