import Head from 'next/head'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { RecoilRoot } from 'recoil'
import { getIsPrivate } from '@/utils/index'
import { Provider } from 'next-auth/client'
import { useSession } from 'next-auth/client'
import api from '@/utils/api'
import { useSettings } from '@/pages/settings'
import Paddle from '@/components/Paddle'
import Splitbee from '@/components/Splitbee'
import Modal from '@/components/Modal'
import '@/components/Progress'
import toast, { Toaster } from 'react-hot-toast'

import '@/styles/globals.css'
import '@/styles/nprogress.css'
import 'highlight.js/styles/github.css'
import 'react-markdown-editor-lite/lib/index.css'

export default function AppWrapper({ Component, pageProps, err }) {
  return (
    <RecoilRoot>
      <Provider session={pageProps.session} options={{ clientMaxAge: 0 }}>
        <App Component={Component} pageProps={pageProps} err={err} />
      </Provider>
    </RecoilRoot>
  )
}

function App({ Component, pageProps }) {
  const [session, loading] = useSession()
  const router = useRouter()
  const pathname = router.pathname
  const isPrivate = getIsPrivate(pathname)

  useSettings()
  useSaveDemoVideo()

  if (loading && isPrivate) {
    return null
  }

  if (!loading && !session && isPrivate) {
    router.replace({
      pathname: '/login',
      query: { callbackUrl: `${document.location.origin}${router.asPath}` },
    })
    return null
  }

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/logos/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/img/logos/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/img/logos/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {/* Crisp */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="367296e5-2d07-41f1-9c3f-3009cec8d753";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
          }}
        />
      </Head>

      <Splitbee />
      <Paddle />
      <Toaster position="top-right" />
      <Modal />
      <Component {...pageProps} />
    </>
  )
}

function useSaveDemoVideo() {
  const [session] = useSession()
  const router = useRouter()

  useEffect(() => {
    async function saveData({ video, annotations }) {
      try {
        window.localStorage.removeItem('demoVideo')
        window.localStorage.removeItem('demoAnnotations')

        const response = await api.post('/videos', {
          ...video,
          _id: undefined,
        })

        const newVideo = response.data.data

        if (annotations) {
          annotations.forEach(async (annotation) => {
            await api.post('/annotations', {
              ...annotation,
              video: newVideo._id,
              _id: undefined,
            })
          })
        }

        router.push(`/videos/${newVideo._id}`)
      } catch (e) {
        //
      }
    }

    if (!session || !window) {
      return
    }

    const video = JSON.parse(window.localStorage.getItem('demoVideo'))

    const annotations = JSON.parse(
      window.localStorage.getItem('demoAnnotations')
    )

    if (video || annotations) {
      toast.promise(
        saveData({
          video,
          annotations,
        }),
        {
          duration: 6000,
          loading: 'Adding demo video...',
          success: 'Video added',
          error: (err) => err.toString(),
        }
      )
    }
  }, [session, router])
}
