import * as ReactHotkeysHook from 'react-hotkeys-hook'
import analytics from '@/utils/analytics'

export function useHotKeys(keys, callback, ...rest) {
  const replacedKeys = keys.replace(
    /CmdOrCtrl/g,
    navigator.platform.toLowerCase().includes('mac') ? 'cmd' : 'ctrl'
  )

  function callbackWithTrack() {
    analytics.track(`Shortcut: ${keys}`)
    callback()
  }

  return ReactHotkeysHook.useHotkeys(replacedKeys, callbackWithTrack, ...rest)
}
