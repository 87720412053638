import useSWR from 'swr'
import axios from 'axios'

export const fetcher = (request) =>
  axios(request || {}).then((response) => response.data && response.data.data)

export default function useRequest(request, options = {}) {
  const key = request && JSON.stringify(request)

  return useSWR(key, () => fetcher(request), options)
}
