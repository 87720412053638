import { Transition } from '@headlessui/react'
import { useState, useRef } from 'react'
import useOnClickOutside from '@/utils/useOnClickOutside'
import useOnEsc from '@/utils/useOnEsc'
import { ChevronDown, ChevronUp } from 'react-feather'
import Premium from '@/components/Premium'

export default function Dropdown({
  options = [],
  onChange,
  children,
  selected = 'Options',
  className = '',
  menuElement,
  menuClassname = '',
  position = 'right',
  onOpenChange = () => {},
}) {
  const container = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  function setIsOpenAndCallback(value) {
    setIsOpen(value)
    onOpenChange(value)
  }

  useOnClickOutside({
    container,
    show: isOpen,
    setShow: setIsOpenAndCallback,
  })
  useOnEsc({ show: isOpen, setShow: setIsOpenAndCallback })
  const selectedOptions = options.find(({ value }) => value === selected)
  const selectedName = (selectedOptions && selectedOptions.name) || selected

  return (
    <div
      className={`relative inline-block text-left ${
        isOpen ? 'z-20' : 'z-10'
      } ${className}`}
    >
      <div className="h-full" ref={container}>
        {children ? (
          <div
            className="cursor-pointer h-full"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()

              setIsOpenAndCallback(!isOpen)
            }}
          >
            {children}
          </div>
        ) : (
          <button
            onClick={() => setIsOpenAndCallback(!isOpen)}
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            {selectedName}
            {isOpen ? (
              <ChevronUp className="-mr-1 ml-2 h-5 w-5" />
            ) : (
              <ChevronDown className="-mr-1 ml-2 h-5 w-5" />
            )}
          </button>
        )}
      </div>

      <Transition
        className={`origin-top-${position} absolute ${position}-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 w-56 ${menuClassname}`}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {menuElement
            ? menuElement
            : options.map(
                ({ name, value, icon, isPremium, selected = false }) => {
                  if (isPremium) {
                    return (
                      <Premium feature={name} key={value} addOpacity>
                        <a
                          className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                          role="menuitem"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            onChange(value)
                            setIsOpenAndCallback(false)
                          }}
                        >
                          {icon}
                          {name}
                        </a>
                      </Premium>
                    )
                  } else {
                    const selectedClass = 'bg-gray-100 pointer-events-none'

                    return (
                      <div
                        key={value}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()

                          onChange(value)
                          setIsOpenAndCallback(false)
                        }}
                        className={`flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${
                          selected ? selectedClass : ''
                        }`}
                        role="menuitem"
                      >
                        {icon}
                        {name}
                      </div>
                    )
                  }
                }
              )}
        </div>
      </Transition>
    </div>
  )
}
