import { atom, useRecoilState } from 'recoil'
import { useEffect } from 'react'

export const videosState = atom({
  key: 'videosState',
  default: null,
})

export const playlistsState = atom({
  key: 'playlistsState',
  default: null,
})

export const videoState = atom({
  key: 'videoState',
  default: null,
})

export const videoAnnotationsState = atom({
  key: 'videoAnnotationsState',
  default: null,
})

export const currentAnnotationState = atom({
  key: 'currentAnnotationState',
  default: null,
})

export const videoProgressState = atom({
  key: 'videoProgressState',
  default: null,
})

export const isPlayingState = atom({
  key: 'isPlayingState',
  default: false,
})

export const isAnnotationsMenuOpenState = atom({
  key: 'isAnnotationsMenuOpenState',
  default: false,
})

export const settingsState = atom({
  key: 'settingsState',
  default: null,
})

export const eventsState = atom({
  key: 'eventsState',
  default: [],
})

export const ocrState = atom({
  key: 'ocrState',
  default: false,
})

export const seekToState = atom({
  key: 'seekToState',
  default: null,
})

export const transcriptState = atom({
  key: 'transcriptState',
  default: null,
})

export const isPremiumState = atom({
  key: 'premiumState',
  default: false,
})

export const isTrialState = atom({
  key: 'trialState',
  default: false,
})

export const modalState = atom({
  key: 'modalState',
  default: null,
})

export const isAppMobileMenuOpenState = atom({
  key: 'isAppMobileMenuOpenState',
  default: false,
})

export const affiliateState = atom({
  key: 'affiliateState',
  default: null,
})

export const tagsState = atom({
  key: 'tagsState',
  default: [],
})

// row | column
export const annotationsViewState = atom({
  key: 'annotationsViewState',
  default: 'row',
})

export const isHiddenPanelState = atom({
  key: 'isHiddenPanelState',
  default: false,
})

// Util

export function createItem({ items, item }) {
  const prev = items || []
  return [...prev, item]
}

export function updateItem({ items, item }) {
  return items.map((i) => {
    if (i._id === item._id) {
      return item
    } else {
      return i
    }
  })
}

export function deleteItem({ items, itemID }) {
  return items.filter((i) => i._id !== itemID)
}

export function updateVideo({ video, setPlaylists, setVideos }) {
  if (video.playlist) {
    setPlaylists((prev) =>
      updateVideoInPlaylist({
        playlists: prev || [],
        video,
      })
    )
  } else {
    setVideos((prev) =>
      updateItem({
        items: prev,
        item: video,
      })
    )
  }
}

export function deleteVideo({ video, setPlaylists, setVideos }) {
  if (video.playlist) {
    setPlaylists((prev) =>
      deleteVideoFromPlaylist({
        playlists: prev || [],
        video,
      })
    )
  } else {
    setVideos((prev) =>
      deleteItem({
        items: prev,
        itemID: video._id,
      })
    )
  }
}

export function addVideoToPlaylist({
  video,
  playlistId,
  setPlaylists,
  setVideos,
}) {
  setPlaylists((prev) =>
    prev.map((playlist) => {
      // Remove from previous playlist
      if (playlist._id === video.playlist) {
        return {
          ...playlist,
          videos: playlist.videos.filter((_video) => _video._id !== video._id),
        }
      }

      // Add to new playlist
      if (playlist._id === playlistId) {
        return {
          ...playlist,
          videos: [
            ...playlist.videos,
            {
              ...video,
              playlist: playlistId,
            },
          ],
        }
      }

      return playlist
    })
  )

  setVideos((prev) =>
    deleteItem({
      items: prev,
      itemID: video._id,
    })
  )
}

function deleteVideoFromPlaylist({ playlists, video }) {
  return playlists.map((playlist) => {
    if (playlist._id === video.playlist) {
      return {
        ...playlist,
        videos: playlist.videos.filter((_video) => _video._id !== video._id),
      }
    } else {
      return playlist
    }
  })
}

function updateVideoInPlaylist({ playlists, video }) {
  return playlists.map((playlist) => {
    const isCurrentPlaylist = playlist._id === video.playlist

    if (!isCurrentPlaylist) {
      return playlist
    }

    const updatedVideos = playlist.videos.map((_video) => {
      if (_video._id === video._id) {
        return video
      } else {
        return _video
      }
    })

    return {
      ...playlist,
      videos: updatedVideos,
    }
  })
}

export function useIsRowView() {
  const [view] = useRecoilStateWithLocalStorage({
    state: annotationsViewState,
    defaultValue: 'row',
  })
  return view === 'row'
}

export function useRecoilStateWithLocalStorage({ state, defaultValue = '' }) {
  const localStorageKey = state.key
  const [element, setElement] = useRecoilState(state)

  useEffect(() => {
    setElement(window.localStorage.getItem(localStorageKey) || defaultValue)
  }, [setElement, defaultValue, localStorageKey])

  function setElementWithLocalStorage(value) {
    window.localStorage.setItem(localStorageKey, value)
    setElement(value)
  }

  function getCastedValue() {
    if (element === 'false' || element === 'true') {
      return JSON.parse(element)
    }

    return element
  }

  return [getCastedValue(), setElementWithLocalStorage]
}
