import AppSection from '@/components/app/AppSection'
import { useState } from 'react'
import { useEffect } from 'react'
import useRequest from '@/utils/useRequest'
import api from '@/utils/api'
import {
  isActiveBilling,
  TRIAL_DAYS,
  getIsTrial,
  getCreatedDaysOld,
  getIsPremium,
} from '@/utils/index'
import analytics from '@/utils/analytics'
import { useRecoilState } from 'recoil'
import { settingsState } from '@/store'
import { isTrialState } from '@/store'
import { isPremiumState } from '@/store'
import { modalState } from '@/store'
import toast from 'react-hot-toast'
import { useSession } from 'next-auth/client'
import { useWaitForPaddleSetup } from '@/components/Paddle'
import Dropdown from '@/components/Dropdown'
import Premium from '@/components/Premium'
import Button from '@/components/Button'
import openCheckout from '@/utils/openCheckout'
import Link from 'next/link'
import classNames from 'classnames'
import PLANS from '@/utils/plans'

export default function Settings() {
  const [settings, setSettings] = useRecoilState(settingsState)
  const [isTrial] = useRecoilState(isTrialState)
  const [isPremium] = useRecoilState(isPremiumState)
  const [session] = useSession()

  return (
    <AppSection title="Settings" canonical="/settings" isLoading={!settings}>
      <General {...{ settings, setSettings }} />
      <Readwise {...{ settings, setSettings }} />
      {isTrial && !isPremium && session && (
        <Trial {...{ user: session.user }} />
      )}
      <Billing {...{ settings, setSettings, isTrial }} />
    </AppSection>
  )
}

function General({ settings, setSettings }) {
  const [isLoading, setIsLoading] = useState(false)
  const languages = [
    {
      name: 'English',
      value: 'en',
    },
    {
      name: 'Spanish',
      value: 'es',
    },
    {
      name: 'Portuguese',
      value: 'pt',
    },
    {
      name: 'French',
      value: 'fr',
    },
    {
      name: 'Italian',
      value: 'it',
    },
    {
      name: 'Bahasa',
      value: 'id',
    },
    {
      name: 'Hindi',
      value: 'hi',
    },
    {
      name: 'Romanian',
      value: 'ro',
    },
  ]

  function onLanguageChange(transcriptLanguage) {
    setSettings({
      ...settings,
      transcriptLanguage,
    })
  }

  function onPauseOnTypeChange() {
    setSettings({
      ...settings,
      pauseOnType: !settings.pauseOnType,
    })
  }

  async function onSave() {
    try {
      setIsLoading(true)
      analytics.track('Saving settings')
      const response = await api.post('/settings', {
        ...settings,
        transcriptLanguage: settings.transcriptLanguage,
        pauseOnType: settings.pauseOnType,
      })
      const newSettings = response.data.data
      setSettings(newSettings)
      toast.success('Settings updated')
    } catch (error) {
      analytics.track('Error saving settings')
      toast.error('Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  const className1 = settings.pauseOnType ? 'bg-primary' : 'bg-gray-200'
  const className2 = settings.pauseOnType ? 'translate-x-5' : 'translate-x-0'

  return (
    <Box title="General">
      <div className="flex items-center mb-5">
        <span className="flex flex-col mr-4" id="toggleLabel">
          <span className="text-lg text-gray-900">
            Pause video when start typing:
          </span>
        </span>
        <button
          type="button"
          aria-pressed="false"
          aria-labelledby="toggleLabel"
          onClick={onPauseOnTypeChange}
          className={`${className1} bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
        >
          <span className="sr-only">Pause video when start typing</span>
          <span
            aria-hidden="true"
            className={`${className2} translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </button>
      </div>
      <div className="flex items-center">
        <span className="flex flex-col mr-4" id="toggleLabel">
          <span className="text-lg text-gray-900">
            Default transcript language:
          </span>
        </span>
        <Dropdown
          options={languages}
          onChange={onLanguageChange}
          selected={settings.transcriptLanguage}
        />
      </div>
      <Button onClick={onSave} isLoading={isLoading} className="mt-5">
        Save
      </Button>
    </Box>
  )
}

function Readwise({ settings, setSettings }) {
  const [isLoading, setIsLoading] = useState(false)

  function onTokenChange(newToken) {
    setSettings({
      ...settings,
      readwiseToken: newToken,
    })
  }

  async function onSave() {
    try {
      setIsLoading(true)
      analytics.track('Syncing with readwise')
      const response = await api.post('/settings', {
        ...settings,
        readwiseToken: settings.readwiseToken,
      })
      const newSettings = response.data.data
      setSettings(newSettings)

      if (newSettings.readwiseToken) {
        api.post('/readwise/sync')
        toast.success('Syncing with readwise')
      } else {
        toast.success('Settings updated')
      }
    } catch (error) {
      analytics.track('Error saving readwise settings')
      toast.error('Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  const value = settings?.readwiseToken ?? ''

  return (
    <Premium
      feature="Readwise Token"
      premiumStyle={{ fontSize: '1.2em' }}
      addOpacity
    >
      <Box
        title={
          <div className="flex items-baseline">
            <h1 className="text-xl font-semibold mb-3">Readwise Token</h1>
            <a
              className="text-primary ml-2"
              href="https://readwise.io/access_token"
              rel="noopener noreferrer"
              target="_blank"
            >
              (Get token)
            </a>
          </div>
        }
      >
        <div className="flex items-center">
          <span className="flex flex-col mr-4" id="toggleLabel">
            <span className="text-lg text-gray-900">Token:</span>
          </span>
          <label htmlFor="token" className="sr-only">
            Token
          </label>
          <input
            type="text"
            name="token"
            id="token"
            onChange={({ target }) => onTokenChange(target.value)}
            className="inline-flex justify-center shadow-sm focus:ring-primary focus:border-primary w-full sm:text-sm border-gray-300 rounded-md"
            value={value}
          />
        </div>
        <Button onClick={onSave} isLoading={isLoading} className="mt-5">
          Save
        </Button>
      </Box>
    </Premium>
  )
}

function Billing({ settings, setSettings, isTrial }) {
  const isLoaded = useWaitForPaddleSetup()
  const [session] = useSession()
  const [isCheckoutOpened, setIsCheckoutOpened] = useState(false)
  const [isPremium, setIsPremium] = useRecoilState(isPremiumState)
  const [, setModal] = useRecoilState(modalState)

  const userId = session.user._id
  const email = session.user.email
  const { billing } = settings

  async function subscribeCallback() {
    window.localStorage.removeItem('savePremium')
    setIsPremium(true)
    const response = await api.get('/settings')
    const newSettings = response.data.data
    setSettings(newSettings)
  }

  async function cancelCallback() {
    const newValue = isActiveBilling({
      cancellationEffectiveDate: settings.billing.nextBillDate,
    })

    setIsPremium(newValue)
  }

  function closeCallback() {
    window.localStorage.removeItem('savePremium')
    setIsCheckoutOpened(false)
  }

  function cancelSubscription() {
    analytics.track('Cancel Subscription')
    window.Paddle.Checkout.open({
      override: billing.cancelUrl,
      passthrough: userId,
      successCallback: cancelCallback,
    })
  }

  function onCheckout({ planType } = {}) {
    setIsCheckoutOpened(true)

    openCheckout({
      email,
      userId,
      successCallback: subscribeCallback,
      closeCallback,
      feature: 'Upgrade Setting',
      planType,
    })
  }

  function askForCancelReason() {
    analytics.track('Ask for cancellation reason')

    setModal({
      title: 'Cancellation Reason',
      body: <CancellationReason cancelSubscription={cancelSubscription} />,
    })
  }

  useEffect(() => {
    if (isPremium) {
      return
    }

    const shouldSavePremium = window.localStorage.getItem('savePremium')

    if (!isCheckoutOpened && isLoaded && shouldSavePremium) {
      try {
        const planType = getPlan(shouldSavePremium)
        onCheckout({ planType })
      } catch (e) {
        console.log('error', e)
      }
    }
  }, [session, isLoaded, isPremium])

  const showUpgrade =
    (!isPremium && !isTrial) ||
    (!isPremium && (isTrial || billing?.cancellationEffectiveDate))

  const isLifetime = getIsLifetime(billing)
  const showUpgradeToLifetime = !showUpgrade && isPremium && !isLifetime
  const showCancel =
    isPremium && !isLifetime && billing && !billing.cancellationEffectiveDate

  return (
    <Box title="Billing">
      {showUpgrade && (
        <Link href="/upgrade">
          <Button className="mt-5">🔥 Upgrade to Premium</Button>
        </Link>
      )}

      {showUpgradeToLifetime && (
        <div>
          <Link href={`/upgrade?planType=${PLANS.LIFETIME.name}`}>
            <Button className="mt-5">🔥 Upgrade to Lifetime plan</Button>
          </Link>
        </div>
      )}

      {showCancel && (
        <div>
          <Button onClick={askForCancelReason} className="mt-5">
            😭 Cancel Subscription
          </Button>
        </div>
      )}

      {billing && billing.nextBillDate && !isLifetime && (
        <div className="mt-5">Next billing: {billing.nextBillDate}</div>
      )}

      {billing && billing.cancellationEffectiveDate && !isLifetime && (
        <div className="mt-5">
          Cancellation Effective Date: {billing.cancellationEffectiveDate}
        </div>
      )}

      {isLifetime && <div>✨ Lifetime plan ✨</div>}
    </Box>
  )
}

function CancellationReason({ cancelSubscription }) {
  const [reason, setReason] = useState('')
  const [feedback, setFeedback] = useState('')
  const [, setModal] = useRecoilState(modalState)

  const options = [
    'Cost',
    'Difficulty of use',
    'Missing functionality',
    'Using other product',
    "I don't use my account",
    'Something else',
  ].map((reasonOption) => ({
    name: reasonOption,
    value: reasonOption.toLowerCase().replaceAll(' ', '-'),
  }))

  function handleSubmit() {
    cancelSubscription()
    analytics.track('Cancellation Reason', { reason, feedback })
    setModal(null)
  }

  return (
    <div>
      <h4 className="font-semibold mb-3">
        * Please indicate the reason for cancelling your account:
      </h4>
      <div className="grid grid-cols-3 gap-4 mb-10">
        {options.map((option) => {
          const reasonClassName = classNames(
            'rounded-md shadow-md border center p-4 border-gray-200 clickable-opacity h-16',
            { 'pointer-events-none border-gray-400': option.value === reason }
          )

          return (
            <div
              key={option.value}
              className={reasonClassName}
              onClick={() => setReason(option.value)}
            >
              {option.name}
            </div>
          )
        })}
      </div>
      <h4 className="font-semibold mb-3">
        Let us know how we can make your experience with Annotate.tv better
        (optional):
      </h4>
      <textarea
        className="shadow-sm focus:ring-primary focus:border-primary w-full border-gray-300 rounded-md"
        value={feedback}
        onChange={({ target }) => setFeedback(target.value)}
      />
      <div className="w-full flex">
        <Button
          className="ml-auto mt-3 !bg-red-500"
          onClick={handleSubmit}
          disabled={!reason}
        >
          Cancel Acccount
        </Button>
      </div>
    </div>
  )
}

function Trial({ user }) {
  const { createdAt } = user
  const createdDaysOld = getCreatedDaysOld(createdAt)
  const trialDaysLeft = TRIAL_DAYS - createdDaysOld

  return (
    <Box title="Trial">
      <div>You have {trialDaysLeft} days left in your premium trial</div>
    </Box>
  )
}

function Box({ title, children }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-10">
      {typeof title === 'string' ? (
        <h1 className="text-xl font-semibold mb-3">{title}</h1>
      ) : (
        title
      )}
      {children}
    </div>
  )
}

export function useSettings() {
  const [session] = useSession()
  const { data: serverSettings, mutate } = useRequest(
    session ? '/api/settings' : null
  )
  const [settings, setSettings] = useRecoilState(settingsState)
  const [, setIsPremium] = useRecoilState(isPremiumState)
  const [, setIsTrial] = useRecoilState(isTrialState)

  useEffect(() => {
    if (session && serverSettings) {
      const isTrial = getIsTrial(session.user)
      const newValue = getIsPremium({
        billing: serverSettings.billing,
        user: session.user,
      })
      setIsTrial(isTrial)
      setIsPremium(newValue)
      setSettings(serverSettings)
    }
  }, [serverSettings, session])

  return [settings, setSettings, mutate]
}

function getPlan(shouldSavePremium) {
  if (typeof shouldSavePremium === 'string') {
    const parsed = JSON.parse(shouldSavePremium)
    return parsed.planType
  } else {
    return false
  }
}

function getIsLifetime(billing) {
  const currentPlanId = billing?.planId
  return +currentPlanId === PLANS.LIFETIME.id
}
