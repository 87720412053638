import Spinner from '@/components/Spinner'
import ReactTooltip from 'react-tooltip'

export default function Button({
  onClick,
  isLoading,
  children,
  disabled,
  className = '',
  name,
  tooltipContent,
  ...rest
}) {
  const isDisabled = isLoading || disabled

  const buttonClassName = isDisabled
    ? `opacity-50 pointer-events-none cursor-not-allowed ${className}`
    : `clickable-opacity ${className}`

  return (
    <>
      <button
        onClick={onClick}
        type="button"
        className={`inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${buttonClassName}`}
        disabled={isDisabled}
        {...(tooltipContent ? { ['data-tip']: true, ['data-for']: name } : {})}
        {...rest}
      >
        {isLoading ? <Spinner size={5} color="white" /> : children}
      </button>
      {tooltipContent && (
        <ReactTooltip
          place="top"
          effect="solid"
          id={name}
          clickable
          delayHide={100}
        >
          <span className="center">{tooltipContent}</span>
        </ReactTooltip>
      )}{' '}
    </>
  )
}
