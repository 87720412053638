import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import Script from 'next/script'

export default function Paddle() {
  useWaitForPaddleSetup()

  return (
    <Script
      src="https://cdn.paddle.com/paddle/paddle.js"
      strategy="afterInteractive"
    />
  )
}

export function useWaitForPaddleSetup() {
  const [isLoaded, setIsLoaded] = useState(false)
  const intervalRef = useRef()

  useEffect(() => {
    const id = setInterval(() => {
      if (window.Paddle) {
        window.Paddle.Setup({ vendor: 120903 })
        clearInterval(intervalRef.current)
        setIsLoaded(true)
      }
    }, 500)

    intervalRef.current = id

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return isLoaded
}
